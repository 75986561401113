<script setup lang="ts">
import VLoader from './VLoader.vue';

import { icons } from '@/assets/icons';

const props = defineProps<{
    text: string;
    disabled?: boolean;
    loading?: boolean;
    isOutlined?: boolean;
    noArrow?: boolean;
    isRed?: boolean;
    loaderProgress?: number;
}>();

const emit = defineEmits<{
    (e: 'click', event: Event): void;
}>();

/**
 * Functions
 */

/**
 * Click on button
 * @param e
 */

function onClick(e: Event) {
    if (props.disabled || props.loading) {
        e.preventDefault();
        e.stopPropagation();

        return;
    }

    emit('click', e);
}
</script>

<template>
    <button
        @click="onClick"
        @keydown.enter="onClick"
        class="btn"
        :disabled="disabled"
        :class="{ outlined: isOutlined, red: isRed, 'pointer-events-none opacity-50': disabled, 'no-arrow': noArrow }"
        :tabindex="disabled ? '-1' : ''"
    >
        <VLoader
            v-if="loading"
            class="mr-2 h-4 w-4 text-white"
            :class="{ 'text-dark-yellow': isOutlined }"
        />

        {{ text }}

        <div
            v-if="!noArrow"
            class="absolute top-1/2 right-6 -translate-y-1/2 rotate-180"
            v-html="icons.arrow"
        ></div>

        <div
            v-if="loaderProgress"
            class="absolute bottom-0 left-0 flex h-[3px] w-full justify-start"
        >
            <div
                class="h-full w-full rounded bg-dark-yellow-600 transition-all duration-200"
                :style="{ maxWidth: `${loaderProgress}%` }"
            ></div>
        </div>
    </button>
</template>
