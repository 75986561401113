import axios from 'axios';
// import VueGtag from 'vue-gtag';
import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import router from '@/router';
import store from '@/store';
import i18n from '@/lang/i18n';
import AuthService from '@/services/auth.service';
import App from '@/App.vue';

import { createPinia } from 'pinia';
import { useDeviceStore } from '@/stores/deviceStore';
import { usePaymentStore } from '@/stores/paymentStore';

import '@ionic/vue/css/core.css';
import '@/assets/style.css';

const pinia = createPinia();
const app = createApp(App);

/**
 * Initialize Sentry as early as possible
 * Disable in dev mode
 */
if (!import.meta.env.DEV) {
    Sentry.init(
        {
            dsn: import.meta.env.VITE_APP_SENTRY_DSN,
            release: 'skaffa@' + import.meta.env.VITE_APP_RELEASE_VERSION, // Must match the release name in vite.config
            dist: import.meta.env.VITE_APP_DIST_VERSION,
            tracesSampleRate: 0.1,
            sampleRate: 0.1,
            integrations: [
                new BrowserTracing({
                    tracingOrigins: ['localhost', 'https://skaffa.fo'],
                    routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
                }),
            ],
            trackComponents: true,
            environment: import.meta.env.MODE,
        },
        SentryVue.init
    );
}

/**
 * Add Authorization Bearer Token to each Request.
 */
axios.interceptors.request.use(async (config) => {
    if (!config.headers) {
        return config;
    }

    const token = await Preferences.get({ key: 'skaffa-token' });

    store.access_token = token.value;

    config.headers.Authorization = `Bearer ${store.access_token}`;

    return config;
});

/**
 * Go to 'Network Error View' when Network Connection is Lost.
 */
Network.addListener('networkStatusChange', (status) => {
    const { connected } = status;

    if (!connected) {
        router.push({ name: 'connection-error' });
    } else {
        if (router.currentRoute.value.name !== 'connection-error') {
            return;
        }

        router.go(-1);
    }
});

/**
 * Listen for messages to close the QuickPay modal after QuickPay callback.
 */
// window.addEventListener('message', (event) => {
//     if (event.data.action === 'closeModal') {
//         store.showModal = false; // Update the store to close the modal
//         console.log('main.ts event listener triggered!');

//         emitter.on
//     }
// }, false);

(async function () {
    /**
     * Get locale from Preferences & set i18n locale.
     */
    const locale = await Preferences.get({ key: 'skaffa-locale' });

    i18n.global.locale.value = locale.value === 'en' ? 'en' : 'fo';

    const user = await AuthService.me();

    if (user) {
        store.user = user;
    } else {
        store.user = null;
    }

    /**
     * Clear cart if is one hour old
     */
    const getCart = await Preferences.get({ key: 'skaffa-cart' });

    if (getCart.value) {
        const cart = JSON.parse(getCart.value);

        // Cart time
        const updatedAt = new Date(cart.updatedAt).getTime();

        const hourInMilliseconds = 60 * 60 * 1000;

        // Time one hour ago
        const oneHourAgo = Date.now() - hourInMilliseconds;

        if (updatedAt <= oneHourAgo) {
            store.cart = null;
            await Preferences.remove({ key: 'skaffa-cart' });
        }
    }

    app.use(IonicVue);
    app.use(router);
    app.use(pinia);
    app.use(i18n);

    /**
     * Initialize Stores - The order is important.
     */

    const deviceStore = useDeviceStore();
    const paymentStore = usePaymentStore();

    await deviceStore.fetchDeviceInfo();
    await paymentStore.initializePaymentStore();

    /**
     * Debug Mode
     */
    // console.log('Platform: ', deviceStore.platform);
    // console.log('Operating System: ', deviceStore.operatingSystem);
    // console.log('Browser: ', deviceStore.browserName);
    // console.log('Is web: ', deviceStore.isWeb);
    // console.log('Is mobile: ', deviceStore.isMobile);
    // console.log('Is Apple device: ', deviceStore.isAppleDevice);
    // console.log('Has Apple Pay: ', paymentStore.hasApplePay);
    // console.log('Has Google Pay: ', paymentStore.hasGooglePay);

    // app.use(
    //     VueGtag,
    //     {
    //         config: { id: import.meta.env.VITE_APP_GTAG_ID },
    //         params: {
    //             anonymize_ip: true,
    //         },
    //     },
    //     router
    // );

    app.mount('#app');
})();

// Add Pusher to window (Required for Socket Connections)
import Pusher from 'pusher-js';
window.Pusher = Pusher;

// TODO: Remove this comment (only deployment trigger)
