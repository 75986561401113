<script setup lang="ts">
import { icons } from '@/assets/icons';

import VButton from '@/components/general/VButton.vue';
import OrdersService from '@/services/orders.service';
import store from '@/store';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

defineProps<{
    active: boolean;
}>();

async function handleClick() {
    if (store.activeOrderId !== null) {
        try {
            await OrdersService.cancel(store.activeOrderId);
        } catch (error) {
            console.error(error);
        }
    }

    store.activeOrderId = null;

    if (route.name === 'checkout') {
        router.push({ name: 'cart' });

        if (store.user) {
            store.success = {
                active: true,
                keepOnScreen: true,
                text: 'cart.success.userCreated.text',
            };
        }
    }

    store.activeLoaderScreen = false;
}
</script>

<template>
    <div
        class="fixed top-0 left-0 z-[500] flex h-screen w-full flex-col items-center justify-center bg-white/95 transition duration-300"
        :class="{ 'pointer-events-none opacity-0': !active }"
    >
        <div
            class="inline-block animate-spin text-dark-yellow"
            v-html="icons.loading"
        ></div>

        <VButton
            v-if="store.loaderScreenHasButton"
            class="mt-12"
            :text="$t('checkout.cancel')"
            isOutlined
            noArrow
            @click="handleClick"
        ></VButton>
    </div>
</template>
