import axios, { type AxiosRequestConfig } from 'axios';

import ResourceService from './_resource.service';

import type CreateOrderRequest from '@/models/requests/create-order.request';
import type OrderResponse from '@/models/responses/order.response';
import type { Order } from '@/models/types/order.model';
import type Receipt from '@/models/types/receipt.model';
import store from '@/store';

export default class OrdersService extends ResourceService<OrderResponse> {
    public static url = '/orders';

    constructor() {
        super(axios, OrdersService.url);
    }

    /**
     * Gets all payment methods
     *
     * @param config AxiosRequestConfig
     * @returns Order[]
     */
    public static getAll(config?: AxiosRequestConfig): Promise<Order[]> {
        return new ResourceService<Order>(axios, OrdersService.url).getAll(config);
    }

    /**
     * Gets a single payment method with the given id
     *
     * @param id Order id
     * @param config AxiosRequestConfig
     * @returns Order
     */
    public static get(id: string, config?: AxiosRequestConfig): Promise<Order> {
        return new ResourceService<Order>(axios, OrdersService.url).get(id, config);
    }

    /**
     * Get all receipts for order
     * @param id
     * @param config
     * @returns
     */
    public static async getReceipts(id: number | string, config?: AxiosRequestConfig): Promise<Receipt> {
        return await axios
            .get(`${store.constants.SKAFFA_API_URL}${this.url}/${id}/receipt`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return new ResourceService<Receipt>(axios, OrdersService.url).handleError(error);
            });
    }

    /**
     * Cancel order
     * @param id
     * @param config
     * @returns
     */
    public static async cancel(id: string, config?: AxiosRequestConfig): Promise<boolean> {
        return await axios
            .post(`${store.constants.SKAFFA_API_URL}${this.url}/${id}/cancel`, config)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return new ResourceService<Receipt>(axios, OrdersService.url).handleError(error);
            });
    }

    /**
     * Creates a new order
     *
     * @param createRequest CreateOrderRequest
     * @param config AxiosRequestConfig
     * @returns
     */
    public static create(createRequest: CreateOrderRequest, config?: AxiosRequestConfig): Promise<OrderResponse> {
        return new ResourceService<CreateOrderRequest, OrderResponse>(axios, OrdersService.url).create(
            createRequest,
            config
        );
    }

    public static async checkMenuAvailability(menu_ids: number[]): Promise<boolean[]> {
        return await axios
            .post(`${store.constants.SKAFFA_API_URL}/menus/check-availability`, { menu_ids })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                return [];
            });
    }
}
