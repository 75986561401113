import { createRouter, createWebHistory } from '@ionic/vue-router';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        /**
         * Home
         */

        {
            path: '/',
            name: 'home',
            component: () => import('../views/order/CategoriesView.vue'),
            meta: {
                index: 0,
                authRequired: false,
                parent: null,
            },
        },

        /**
         * Settings
         */

        {
            path: '/settings',
            name: 'settings',
            component: () => import('../views/settings/SettingsView.vue'),
            meta: {
                index: 500,
                authRequired: false,
                parent: 'home',
            },
        },

        {
            path: '/settings/user-information',
            name: 'user-information',
            component: () => import('../views/settings/UserInformationView.vue'),
            meta: {
                index: 501,
                authRequired: true,
                parent: 'settings',
            },
        },

        // TODO: Removed saved card option
        {
            path: '/settings/payment-methods',
            name: 'payment-methods',
            component: () => import('../views/settings/PaymentMethodsView.vue'),
            meta: {
                index: 501,
                authRequired: true,
                parent: 'settings',
            },
        },

        {
            path: '/settings/payment-methods/:paymentId',
            name: 'payment-method',
            component: () => import('../views/settings/PaymentMethodView.vue'),
            meta: {
                index: 502,
                authRequired: true,
                parent: 'payment-methods',
            },
        },

        {
            path: '/settings/payment-methods/save-card-response',
            name: 'save-card-response',
            component: () => import('../views/quickpay/ResponseView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'payment-methods',
            },
        },

        {
            path: '/settings/addresses',
            name: 'addresses',
            component: () => import('../views/settings/AddressesView.vue'),
            meta: {
                index: 501,
                authRequired: true,
                parent: 'settings',
            },
        },

        {
            path: '/settings/addresses/:addressId',
            name: 'address',
            component: () => import('../views/settings/AddressView.vue'),
            meta: {
                index: 502,
                authRequired: true,
                parent: 'addresses',
            },
        },

        {
            path: '/settings/addresses/new',
            name: 'new-address',
            component: () => import('../views/settings/AddressView.vue'),
            meta: {
                index: 502,
                authRequired: true,
                parent: 'addresses',
            },
        },

        {
            path: '/settings/language',
            name: 'language',
            component: () => import('../views/settings/LanguageView.vue'),
            meta: {
                index: 501,
                authRequired: false,
                parent: 'settings',
            },
        },

        {
            path: '/settings/receipts',
            name: 'receipts',
            component: () => import('../views/settings/ReceiptsView.vue'),
            meta: {
                index: 501,
                authRequired: true,
                parent: 'settings',
            },
        },

        {
            path: '/settings/receipts/:receiptId',
            name: 'receipt',
            component: () => import('../views/settings/ReceiptView.vue'),
            meta: {
                index: 502,
                authRequired: true,
                parent: 'receipt',
            },
        },

        {
            path: '/settings/devices',
            name: 'devices',
            component: () => import('../views/settings/DevicesView.vue'),
            meta: {
                index: 501,
                authRequired: true,
                parent: 'settings',
            },
        },

        {
            path: '/settings/privacy',
            name: 'privacy',
            component: () => import('../views/settings/PrivacyPolicyView.vue'),
            meta: {
                index: 501,
                authRequired: false,
                parent: 'settings',
            },
        },

        {
            path: '/settings/terms',
            name: 'terms',
            component: () => import('../views/settings/TermsView.vue'),
            meta: {
                index: 501,
                authRequired: false,
                parent: 'settings',
            },
        },

        {
            path: '/settings/feedback',
            name: 'feedback',
            component: () =>import('../views/settings/FeedbackView.vue'),
            meta: {
                index: 501,
                authRequired: false,
                parent: 'settings',
            },

        },

        /**
         * Login
         */

        {
            path: '/settings/login',
            name: 'login',
            component: () => import('../views/auth/LoginView.vue'),
            meta: {
                index: 501,
                authRequired: false,
                parent: 'settings',
            },
        },

        {
            path: '/settings/login/forgot-password',
            name: 'forgot-password',
            component: () => import('../views/auth/ForgotPassword.vue'),
            meta: {
                index: 502,
                authRequired: false,
                parent: 'login',
            },
        },

        /**
         * Register
         */

        {
            path: '/settings/register',
            name: 'register',
            component: () => import('../views/auth/RegisterView.vue'),
            meta: {
                index: 502,
                authRequired: false,
                parent: 'login',
            },
        },

        {
            path: '/settings/register/success',
            name: 'register-success',
            component: () => import('../views/auth/RegisterSuccessView.vue'),
            meta: {
                index: 503,
                authRequired: false,
                parent: 'home',
            },
        },

        /**
         * Reset Password
         */

        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('../views/auth/ResetPasswordView.vue'),
            meta: {
                index: 504,
                authRequired: false,
                parent: 'login',
            },
        },
        /**
         * Cart
         */

        {
            path: '/cart',
            name: 'cart',
            component: () => import('../views/checkout/CartView.vue'),
            meta: {
                index: 200,
                authRequired: false,
                parent: 'home',
            },
        },

        {
            path: '/cart/delivery',
            name: 'cart-delivery',
            component: () => import('../views/checkout/DeliveryView.vue'),
            meta: {
                index: 201,
                authRequired: true,
                parent: 'cart',
            },
        },

        {
            path: '/cart/delivery/new-address',
            name: 'cart-delivery-new-address',
            component: () => import('../views/checkout/DeliveryView.vue'),
            meta: {
                index: 202,
                authRequired: true,
                parent: 'cart-delivery',
            },
        },

        {
            path: '/cart/addresses',
            name: 'cart-addresses',
            component: () => import('../views/settings/AddressesView.vue'),
            meta: {
                index: 202,
                authRequired: true,
                parent: 'cart',
            },
        },

        {
            path: '/cart/addresses/new',
            name: 'cart-new-address',
            component: () => import('../views/settings/AddressView.vue'),
            meta: {
                index: 203,
                authRequired: true,
                parent: 'cart-addresses',
            },
        },

        /**
         * Checkout
         */

        {
            path: '/cart/checkout',
            name: 'checkout',
            component: () => import('../views/checkout/CheckoutView.vue'),
            meta: {
                index: 201,
                authRequired: false,
                parent: 'cart',
            },
        },

        {
            path: '/cart/login',
            name: 'cart-login',
            component: () => import('../views/auth/LoginView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'checkout',
            },
        },

        {
            path: '/cart/login/forgot-password',
            name: 'cart-forgot-password',
            component: () => import('../views/auth/ForgotPassword.vue'),
            meta: {
                index: 203,
                authRequired: false,
                parent: 'cart-login',
            },
        },

        {
            path: '/cart/success',
            name: 'checkout-success',
            component: () => import('../views/checkout/SuccessView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'home',
            },
        },

        {
            path: '/cart/failed/:message?',
            name: 'checkout-failed',
            component: () => import('../views/checkout/FailedView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'cart',
            },
            props: true
        },

        {
            path: '/settings/payment-methods/failed',
            name: 'payment-methods-failed',
            component: () => import('../views/checkout/FailedSavedCardView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'settings',
            },
        },

        {
            path: '/cart/checkout/create-payment-response',
            name: 'checkout-create-payment-response',
            component: () => import('../views/quickpay/ResponseView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'checkout',
            },
        },

        {
            path: '/cart/create-payment-response',
            name: 'cart-create-payment-response',
            component: () => import('../views/quickpay/ResponseView.vue'),
            meta: {
                index: 202,
                authRequired: false,
                parent: 'cart',
            },
        },

        {
            path: '/cart/receipt/:orderId',
            name: 'checkout-receipt',
            component: () => import('../views/settings/ReceiptView.vue'),
            meta: {
                index: 203,
                authRequired: false,
                parent: 'home',
            },
        },

        {
            path: '/cart/payment-methods',
            name: 'cart-payment-methods',
            component: () => import('../views/settings/PaymentMethodsView.vue'),
            meta: {
                index: 201,
                authRequired: true,
                parent: 'cart',
            },
        },




        /**
         * Connection Error
         */

        {
            path: '/connection-error',
            name: 'connection-error',
            component: () => import('../views/ConnectionErrorView.vue'),
            meta: {
                index: 999,
                authRequired: false,
                parent: null,
            },
        },

        {
            path: '/:locationId',
            name: 'location',
            component: () => import('../views/order/LocationView.vue'),
            meta: {
                index: 100,
                authRequired: false,
                parent: 'home',
            },
        },

        /**
         * Order-flow
         */

        {
            path: '/:locationId/menus/:menuId/products/:productId',
            name: 'product',
            component: () => import('../views/order/ProductView.vue'),
            meta: {
                index: 101,
                authRequired: false,
                parent: 'location',
            },
        },

        {
            path: '/:locationId/menus/:menuId/products/:productId/sides',
            name: 'sides',
            component: () => import('../views/order/SidesView.vue'),
            meta: {
                index: 102,
                authRequired: false,
                parent: 'product',
            },
        },

        {
            path: '/:locationId/menus/:menuId/products/:productId/sides/:sideId',
            name: 'side',
            component: () => import('../views/order/ProductView.vue'),
            meta: {
                index: 103,
                authRequired: false,
                parent: 'sides',
            },
        },

        {
            path: '/:locationId/menus/:menuId/products/:productId/drinks',
            name: 'drinks',
            component: () => import('../views/order/DrinksView.vue'),
            meta: {
                index: 104,
                authRequired: false,
                parent: 'product',
            },
        },

        {
            path: '/:locationId/menus/:menuId/products/:productId/drinks/:drinkId',
            name: 'drink',
            component: () => import('../views/order/ProductView.vue'),
            meta: {
                index: 105,
                authRequired: false,
                parent: 'drinks',
            },
        },

        {
            path: '/:locationId/success',
            name: 'order-success',
            component: () => import('../views/order/SuccessView.vue'),
            meta: {
                index: 106,
                authRequired: false,
                parent: 'location',
            },
            beforeEnter: (to, from) => {
                if (
                    from.name !== 'product' &&
                    from.name !== 'sides' &&
                    from.name !== 'side' &&
                    from.name !== 'drinks' &&
                    from.name !== 'drink'
                ) {
                    return false;
                }
            },
        },

        /**
         * 404
         */

        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('../views/404.vue'),
            meta: {
                index: 999,
                authRequired: false,
                parent: 'home',
            },
        },
    ],
});

export default router;
