import store from '@/store';

import type Error from '@/models/types/error.model';

export function error(options: Error) {
    store.error = {
        ...options,
        active: true,
    };
}
