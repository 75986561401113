import { reactive } from 'vue';

import app_store from './app-store.svg?raw';
import app_store_fo from './app-store-fo.svg?raw';
import arrow from './arrow.svg?raw';
import cart from './cart.svg?raw';
import cart_minus from './cart-minus.svg?raw';
import cart_plus from './cart-plus.svg?raw';
import checkmark from './checkmark.svg?raw';
import chef from './chef.svg?raw';
import chevron from './chevron.svg?raw';
import close from './close.svg?raw';
import close_large from './close-large.svg?raw';
import cutlery from './cutlery.svg?raw';
import delivery from './delivery.svg?raw';
import flag_en from './flag-en.svg?raw';
import flag_fo from './flag-fo.svg?raw';
import google_play from './google-play.svg?raw';
import google_play_fo from './google-play-fo.svg?raw';
import header from './header.svg?raw';
import heart from './heart.svg?raw';
import heart_cat from './heart-category.svg?raw';
import language_checkmark from './language-checkmark.svg?raw';
import like from './like.svg?raw';
import like_filled from './like-filled.svg?raw';
import loading from './loading-slim.svg?raw';
import logo from './logo.svg?raw';
import minus from './minus.svg?raw';
import no_network from './no-network.svg?raw';
import pizza from './pizza.svg?raw';
import plus from './plus.svg?raw';
import refresher from './refresher.svg?raw';
import romanticDinner from './romantic-dinner.svg?raw';
import sad_smiley from './sad-smiley.svg?raw';
import search from './search.svg?raw';
import signIn from './sign-in.svg?raw';
import signOut from './sign-out.svg?raw';
import small_arrow from './small-arrow.svg?raw';
import time from './time.svg?raw';
import trash from './trash.svg?raw';
import user from './user.svg?raw';
import userCheck from './user-check.svg?raw';
import userPlus from './user-plus.svg?raw';
import warning from './warning.svg?raw';

import default_card from './cards/default-card.svg?raw';
import mastercard from './cards/mastercard.svg?raw';
import mc from './cards/mastercard.svg?raw';
import visa from './cards/visa.svg?raw';

export const icons = reactive({
    app_store_fo,
    app_store,
    arrow,
    cart_minus,
    cart_plus,
    cart,
    checkmark,
    chef,
    chevron,
    close_large,
    close,
    cutlery,
    default_card,
    delivery,
    flag_en,
    flag_fo,
    google_play_fo,
    google_play,
    header,
    heart_cat,
    heart,
    language_checkmark,
    like_filled,
    like,
    loading,
    logo,
    mastercard,
    mc,
    minus,
    no_network,
    pizza,
    plus,
    refresher,
    romanticDinner,
    sad_smiley,
    search,
    signIn,
    signOut,
    small_arrow,
    time,
    trash,
    user,
    userCheck,
    userPlus,
    visa,
    warning,
});
