import axios, { AxiosError } from 'axios';

import type Token from '@/models/types/token.model';
import type User from '@/models/types/user.model';

import type ForgotPasswordRequest from '@/models/requests/forgot-password.request';
import type LoginRequest from '@/models/requests/login.request';
import type ResetPasswordRequest from '@/models/requests/reset-password.request';
import type LoginResponse from '@/models/responses/login.response';
import store from '@/store';

export default class AuthService {
    public static url = `${store.constants.SKAFFA_API_URL}/auth`;

    /**
     * Logs a user in. Returns an access token
     *
     * @param loginRequest LoginRequest
     * @returns LoginResponse
     */
    public static async login(loginRequest: LoginRequest): Promise<LoginResponse> {
        return await axios
            .post(`${AuthService.url}/login`, loginRequest)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                AuthService.handleError(error);
            });
    }

    /**
     * Requests a forgot password e-mail
     *
     * @param forgotPasswordRequest ForgotPasswordRequest
     * @returns boolean
     */
    public static async forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Promise<boolean> {
        return await axios
            .post(`${AuthService.url}/forgot-password`, forgotPasswordRequest)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                AuthService.handleError(error);
            });
    }

    /**
     * Resets a password
     *
     * @param resetPasswordRequest ResetPasswordRequest
     * @returns boolean
     */
    public static async resetPassword(resetPasswordRequest: ResetPasswordRequest): Promise<boolean> {
        return await axios
            .post(`${AuthService.url}/reset-password`, resetPasswordRequest)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                AuthService.handleError(error);
            });
    }

    /**
     * Returns the logged in user
     *
     * @returns User
     */
    public static async me(): Promise<User> {
        return await axios
            .get(`${AuthService.url}/me`)
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                // Not logged in
            });
    }

    /**
     * Returns the logged in users' tokens
     *
     * @returns Token[]
     */
    public static async tokens(): Promise<Token[]> {
        return await axios
            .get(`${AuthService.url}/tokens`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                AuthService.handleError(error);
            });
    }

    /**
     * Deletes a token with the provided id
     *
     * @param id Token id
     * @returns boolean
     */
    public static async deleteToken(id: number | string): Promise<boolean> {
        return await axios
            .delete(`${AuthService.url}/tokens/${id}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                AuthService.handleError(error);
            });
    }

    /**
     * Handles request errors
     *
     * @param error AxiosError
     */
    private static handleError(error: AxiosError): boolean {
        console.error(`Error: ${error.response}`);

        return false;
    }
}
