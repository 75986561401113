import { Preferences } from '@capacitor/preferences';
import store from '@/store';

import router from './routes';

/**
 * Router Transition Middleware.
 */
router.beforeEach(async (to, from) => {
    /**
     * Add slide effect
     */
    if (from.meta?.index === undefined || to.meta.index === from.meta.index) {
        store.transitionName = 'fade-view';
    } else if (
        typeof to.meta.index === 'number' &&
        typeof from.meta.index === 'number' &&
        to.meta.index > from.meta.index
    ) {
        store.transitionName = 'slide-left';
    } else {
        store.transitionName = 'slide-right';
    }

    if (window.innerWidth > 768) {
        store.transitionName = 'fade-view';
    }

    /**
     * Remove active uuid if routing outside of orderflow
     */
    if (
        to.name !== 'product' &&
        to.name !== 'side' &&
        to.name !== 'drink' &&
        to.name !== 'sides' &&
        to.name !== 'drinks' &&
        to.name !== 'order-success'
    ) {
        await Preferences.remove({ key: 'skaffa-orderflow' });
    }

    /**
     * Should routing go through filter
     */
    if (store.checkRoute) {
        if (to.meta.authRequired && !store.user) {
            store.checkRoute = false;
            return { name: 'login' };
        }

        const getOrderflow = await Preferences.get({ key: 'skaffa-orderflow' });

        /**
         * Check from settings
         */
        if (from.name === 'settings' && !to.path.includes('settings')) {
            store.checkRoute = false;

            if (store.settingsPathBeforeOpen.length > 0 && to.name !== 'home') {
                const path = store.settingsPathBeforeOpen;
                store.settingsPathBeforeOpen = '';
                return { path };
            }

            return { name: 'home' };
        }

        /**
         * Check to settings
         */
        if (
            to.name === 'settings' &&
            from.name !== 'sides' &&
            from.name !== 'side' &&
            from.name !== 'drinks' &&
            from.name !== 'drink' &&
            from.name !== 'order-success' &&
            from.name !== 'checkout-success' &&
            !from.path.includes('settings')
        ) {
            if (from.name === 'checkout-failed') {
                store.settingsPathBeforeOpen = '/cart';
            } else {
                store.settingsPathBeforeOpen = from.path;
            }
        }

        /**
         * Check orderflow
         */
        if (
            (to.name === 'sides' || to.name === 'side' || to.name === 'drinks' || to.name === 'drink') &&
            !getOrderflow.value
        ) {
            return false;
        }

        /**
         * Check home screen
         */
        if (
            from.name === 'home' &&
            to.name !== 'home' &&
            to.name !== 'location' &&
            to.name !== 'location-slug' &&
            to.name !== 'cart' &&
            to.name !== 'settings'
        ) {
            return false;
        }

        /**
         * Check location screen
         */
        if (
            from.name === 'location' &&
            to.name !== 'home' &&
            to.name !== 'product' &&
            to.name !== 'cart' &&
            to.name !== 'settings'
        ) {
            store.checkRoute = false;
            return { name: 'home' };
        }

        /**
         * Check from order added to cart
         */

        if (from.name === 'order-success' && to.name !== 'cart' && to.name !== 'location' && to.name !== 'settings') {
            store.checkRoute = false;

            if (getOrderflow.value) {
                const orderflow = JSON.parse(getOrderflow.value);

                const locationId = orderflow.location.id;

                return { name: 'location', params: { locationId } };
            }

            return { name: 'home' };
        }

        /**
         * Check from cart
         */

        if (
            (from.name === 'cart' && to.name === 'product' && !getOrderflow.value) ||
            (from.name === 'cart' &&
                to.name !== 'product' &&
                !to.path.includes('cart') &&
                to.name !== 'settings' &&
                to.name !== 'home')
        ) {
            store.checkRoute = false;

            const getCart = await Preferences.get({ key: 'skaffa-cart' });

            if (getCart.value) {
                const cart = JSON.parse(getCart.value);

                const locationId = cart.location.slug || cart.location.id;

                return { name: 'location', params: { locationId } };
            }

            return { name: 'home' };
        }

        /**
         * Check from checkout success
         */
        if (
            from.name === 'checkout-success' &&
            to.name !== 'settings' &&
            to.name !== 'order' &&
            to.name !== 'checkout-receipt'
        ) {
            store.checkRoute = false;

            return { name: 'home' };
        }

        /**
         * Check from checkout failed
         */
        if (from.name === 'checkout-failed' && to.name !== 'settings') {
            store.checkRoute = false;

            return { name: 'cart' };
        }
    }
});

router.afterEach(() => {
    store.checkRoute = true;
});

export default router;
