import { defineStore } from 'pinia';
import i18n from '@/lang/i18n';

import store from '@/store';
import axios from 'axios';
import { useDeviceStore } from '@/stores/deviceStore';

/**
 * Constants
 */
const SKAFFA_BASE_URL = window.location.origin;
const SKAFFA_API_URL = store.constants.SKAFFA_API_URL;

// Backend endpoints
const CALLBACK_ENDPOINT = '/quickpay/callback';
const CREATE_PAYMENT_ENDPOINT = '/quickpay/create-payment';

// Frontend routes
const CART_BASE = '/cart';
const CHECKOUT_BASE = '/cart/checkout';
const PAYMENT_METHODS_BASE = '/settings/payment-methods';
const CREATE_PAYMENT_RESPONSE = '/create-payment-response';
const SAVE_CARD_RESPONSE = '/save-card-response';

const CURRENCY = 'DKK';
const COUNTRY_CODE = 'FRO';

export const usePaymentStore = defineStore('paymentStore', {
    state: () => ({
        // Variable states
        isApplePayAvailable: false,
        isGooglePayAvailable: false,
        postData: {},
        paymentUrl: '',
        showModal: false,
        triggerCancel: false,
        triggerSuccessMessage: false,
    }),
    getters: {
        hasApplePay: (state) => state.isApplePayAvailable,
        hasGooglePay: (state) => state.isGooglePayAvailable,
    },
    actions: {
        async initializePaymentStore() {
            await this.checkPaymentAvailability();
            await this.initializeModalState();
        },

        async checkPaymentAvailability() {
            const deviceStore = useDeviceStore();

            if (!deviceStore.getInfo) {
                await deviceStore.fetchDeviceInfo().catch((error) => {
                    console.error('Failed to fetch device info:', error);
                });
            }

            if (deviceStore.browserName === 'Firefox') {
                console.log('Apple Pay not supported on Firefox');
                console.log('Google Pay not supported on Firefox');

                this.isApplePayAvailable = false;
                this.isGooglePayAvailable = false;

                return;
            }

            await this.checkApplePayAvailability();
            await this.checkGooglePayAvailability();
        },

        async checkApplePayAvailability() {
            try {
                if (window.ApplePaySession) {
                    this.isApplePayAvailable = true;
                } else {
                    this.isApplePayAvailable = false;
                }
            } catch (error) {
                console.error('Error checking Apple Pay availability:', error);
                this.isApplePayAvailable = false;
            }
        },

        async checkGooglePayAvailability() {
            const deviceStore = useDeviceStore();
            if (!deviceStore.getInfo) {
                await deviceStore.fetchDeviceInfo().catch((error) => {
                    console.error('Failed to fetch device info:', error);
                    return;
                });
            }

            try {
                // Define your Google Pay API version
                const baseRequest = {
                    apiVersion: 2,
                    apiVersionMinor: 0,
                };

                // Request a payment token for your payment provider
                const tokenizationSpecification = {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: 'quickpay',
                        gatewayMerchantId: '184367',
                    },
                };

                // Define supported payment card networks
                const allowedCardNetworks = ['MASTERCARD', 'VISA'];
                const allowedCardAuthMethods = ['CRYPTOGRAM_3DS'];

                // Only Android supports cryptogram 3DS
                // if (deviceStore.isAndroid) {
                //     allowedCardAuthMethods.push('CRYPTOGRAM_3DS');
                // }

                // Describe your allowed payment methods
                const baseCardPaymentMethod = {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: allowedCardAuthMethods,
                        allowedCardNetworks: allowedCardNetworks,
                    },
                };

                // Extend the base request with your gateway information
                const cardPaymentMethod = Object.assign(
                    { tokenizationSpecification: tokenizationSpecification },
                    baseCardPaymentMethod
                );

                // Create a payments client
                const paymentsClient = new google.payments.api.PaymentsClient({ environment: 'TEST' });

                // Determine readiness to pay with the Google Pay API
                const isReadyToPayRequest = {
                    ...baseRequest,
                    allowedPaymentMethods: [baseCardPaymentMethod],
                };

                isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];

                paymentsClient
                    .isReadyToPay(isReadyToPayRequest)
                    .then((response: typeof IsReadyToPayResponse) => {
                        if (response.result) {
                            this.isGooglePayAvailable = true;
                        }
                    })
                    .catch((err: any) => {
                        console.error(err);
                        this.isGooglePayAvailable = false;
                    });

                this.isGooglePayAvailable = true;
            } catch (err) {
                console.error('checkGooglePayAvailability failed: ', err);
                this.isGooglePayAvailable = false;
            }
        },

        async createPayment(cartPrice: number | undefined, parent: 'cart' | 'checkout' | 'payment-methods') {
            if (cartPrice === undefined) {
                console.error('cartPrice is undefined');
                return false;
            }

            await this.createPostData(parent, cartPrice);

            const url = `${SKAFFA_API_URL}${CREATE_PAYMENT_ENDPOINT}`;
            try {
                const response = await axios.post(url, this.postData);
                this.paymentUrl = response.data;

                return true;
            } catch (error) {
                console.error('Failed to create payment:', error);
                return false;
            }
        },

        async payWithSavedCard(
            cartPrice: number | undefined,
            quickpayCardId: string | undefined,
            parent: 'cart' | 'checkout' | 'payment-methods'
        ) {
            if (cartPrice === undefined) {
                console.error('cartPrice is undefined');
                return false;
            }

            if (quickpayCardId === undefined) {
                console.error('quickpayCardId is undefined');
                return false;
            }

            // Create a token for the saved card
            const createTokenUrl = SKAFFA_API_URL + '/quickpay/card/create-token/' + quickpayCardId;
            const createTokenReponse = await axios.post(createTokenUrl);

            this.createPostData(parent, cartPrice);

            // create payment and link
            const createPaymentUrl = SKAFFA_API_URL + '/quickpay/create-payment-token';
            const createPaymentForTokenReponse = await axios.post(createPaymentUrl, this.postData);

            // prepares data for payment authorization with saved card
            const authorizePaymentPostData = {
                amount: cartPrice * 100,
                callback_url: `${SKAFFA_API_URL}${CALLBACK_ENDPOINT}`,
                card_token: createTokenReponse.data,
            };

            // authorizes payment with the saved card token
            const authorizePaymentUrl =
                SKAFFA_API_URL + '/quickpay/payments/authorize-token/' + createPaymentForTokenReponse.data.id;

            try {
                await axios.post(authorizePaymentUrl, authorizePaymentPostData);
                return true;
            } catch (error) {
                console.error('Error paying with saved card:', error);
                store.error = {
                    active: true,
                    text: 'checkout.failed.text1',
                };
                return false;
            }
        },

        async createPostData(parent: 'cart' | 'checkout' | 'payment-methods', cartPrice?: number) {
            
            // Initialize base post data object
            this.postData = {
                language: i18n.global.locale.value as 'en' | 'fo',
                framed: true,
            };

            let route: string;
            let callbackUrlKey: string = 'callback_url';
            let continueUrlKey: string = 'continue_url';
            let cancelUrlKey: string = 'cancel_url';

            if (parent === 'cart' || parent === 'checkout') {
                if (cartPrice === undefined) {
                    console.error('cartPrice is undefined');
                    return false;
                }

                // Add order data
                this.postData = {
                    ...this.postData,
                    order_id: store.orderId,
                    currency: CURRENCY,
                    amount: cartPrice * 100, // convert to Danish oyrir
                    has_apple_pay: this.isApplePayAvailable,
                    has_google_pay: this.isGooglePayAvailable,
                    invoice_address_name: store.location?.restaurant.name,
                    invoice_address_street: store.location?.address,
                    invoice_address_city: store.location?.city,
                    invoice_address_zip_code: store.location?.zip,
                    invoice_address_country_code: COUNTRY_CODE,
                    invoice_address_mobile_number: store.location?.phone,
                };

                // Define route whether it's cart or checkout
                route =
                    parent === 'cart'
                        ? `${CART_BASE}${CREATE_PAYMENT_RESPONSE}`
                        : `${CHECKOUT_BASE}${CREATE_PAYMENT_RESPONSE}`;
            } else if (parent === 'payment-methods') {
                route = `${PAYMENT_METHODS_BASE}${SAVE_CARD_RESPONSE}`;

                // QuickPay cards service has different key names
                callbackUrlKey = 'callbackurl';
                continueUrlKey = 'continueurl';
                cancelUrlKey = 'cancelurl';
            } else {
                console.error('parent is undefined');
                return false;
            }

            // Add callback urls
            this.postData = {
                ...this.postData,
                [callbackUrlKey]: `${SKAFFA_API_URL}${CALLBACK_ENDPOINT}`,
                [continueUrlKey]: `${SKAFFA_BASE_URL}${route}?parent=${parent}&type=continue`,
                [cancelUrlKey]: `${SKAFFA_BASE_URL}${route}?parent=${parent}&type=cancel`,
            };

            return this.postData;
        },

        async openModal() {
            sessionStorage.setItem('showModal', 'true');

            if (!this.showModal) {
                console.log('PaymentStore: Opening modal');
                this.showModal = true;
            } else {
                console.log('Modal already open');
            }
        },

        async closeModal() {
            sessionStorage.setItem('showModal', 'false');

            if (this.showModal) {
                console.log('PaymentStore: Closing modal');
                this.showModal = false;
            } else {
                console.log('Modal already closed');
            }
        },

        async initializeModalState() {
            // console.log('PaymentStore: Initializing modal state');
            const modalState = sessionStorage.getItem('showModal');
            this.showModal = modalState === 'true';
        },
    },
});
