<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';

import { Network } from '@capacitor/network';

import store from '@/store';

import { popup } from '@/helpers';

import VSplash from '@/components/VSplash.vue';
import VLoadScreen from '@/components/VLoadScreen.vue';

/**
 * Mounted
 */

onMounted(async () => {
    setTimeout(() => {
        store.activeSplash = false;
    }, 1000);

    await initNetwork();
});

/**
 * Functions
 */

/**
 * Check if the user has access to the Network.
 */
async function initNetwork() {
    const networkStatus = await Network.getStatus();

    // Show the Network Error Popup
    const showPopup = () => {
        popup({
            title: 'general.network.popup.noNetwork.title',
            text: 'general.network.popup.noNetwork.text',
            required: true,
            buttons: [
                {
                    text: 'general.network.popup.noNetwork.buttonText',
                    callback: () => initNetwork(),
                },
            ],
        });
    };

    // The User does not have an Internet Connection
    if (!networkStatus.connected) {
        showPopup();
    }

    // Listen for Network Status changes
    Network.addListener('networkStatusChange', (status) => {
        if (!status.connected) {
            showPopup();
        }
    });
}
</script>

<template>
    <VSplash :active="store.activeSplash" />

    <VLoadScreen :active="store.activeLoaderScreen" />

    <div class="columns relative flex min-h-screen w-full max-w-screen-md md:min-h-0 md:bg-[#FAFAFA]">
        <RouterView v-slot="{ Component }">
            <component
                :is="Component"
                class="w-full"
            />
        </RouterView>
    </div>
</template>
