import { error as showErrorMessage } from '@/helpers';
import type { AxiosErrorData } from '@/models/types/error.model';
import store from '@/store';

import type { AxiosResponse, AxiosError, AxiosStatic, AxiosRequestConfig } from 'axios';

export default class ResourceService<Type, ReturnType = Type> {
    private axios: AxiosStatic;
    private url: string;

    constructor(axios: AxiosStatic, path: string) {
        this.axios = axios;
        this.url = store.constants.SKAFFA_API_URL + path;
    }

    /**
     * Gets all the resources
     *
     * @param config AxiosRequestConfig
     * @returns Resource[]
     */
    async getAll(config?: AxiosRequestConfig): Promise<ReturnType[]> {
        return await this.axios
            .get<Type[]>(this.url, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Gets a single resource with the given id
     *
     * @param id Resource id
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async get(id: string | number, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .get<Type>(`${this.url}/${id}`, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Creates a new resource
     *
     * @param createResourceRequest
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async create(createResourceRequest?: Type, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .post<Type>(this.url, createResourceRequest, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Updates a resource with the given id
     *
     * @param id Resource id
     * @param updateResourceRequest
     * @param config AxiosRequestConfig
     * @returns Resource
     */
    async update(id: string | number, updateResourceRequest: Type, config?: AxiosRequestConfig): Promise<ReturnType> {
        return await this.axios
            .patch<Type>(`${this.url}/${id}`, updateResourceRequest, config)
            .then((response: AxiosResponse) => {
                return response.data;
            })
            .catch((error: AxiosError) => {
                this.handleError(error);
            });
    }

    /**
     * Deletes a resource with the given id
     *
     * @param id Resource id
     * @param config AxiosRequestConfig
     * @returns boolean
     */
    async delete(id: string | number, config?: AxiosRequestConfig): Promise<boolean> {
        return await this.axios
            .delete<boolean>(`${this.url}/${id}`, config)
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    /**
     * Handles request erros
     * @param error
     */
    public handleError(error: AxiosError): void {
        if (this.axios.isAxiosError(error)) {
            const axiosError: AxiosError = error;

            if (axiosError.message) {
                console.error(`Axios error [${this.constructor.name}]: ${axiosError.message}`);
            }

            const errorData: AxiosErrorData = error.response?.data as AxiosErrorData;

            if (errorData.message) {
                showErrorMessage({
                    text: errorData.message,
                    fromErrorResponse: true,
                });
            }
        }

        console.error(`Unexpected error [${this.constructor.name}]: `, error);
    }
}
