import { createI18n } from 'vue-i18n';

import translations from './translations';

const i18n = createI18n({
    locale: 'en',
    messages: translations,
    legacy: false,
});

export default i18n;
