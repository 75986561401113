import { defineStore } from 'pinia';
import { Device } from '@capacitor/device';
import type { DeviceInfo } from '@capacitor/device';

export const useDeviceStore = defineStore('deviceStore', {
    state: () => ({
        device: null as DeviceInfo | null,
        operatingSystem: '',
        platform: '',
        userAgent: '',
    }),
    getters: {
        getInfo: (state) => state.device,
        isWeb: (state) => state.platform === 'web',
        isMobile: (state) => state.operatingSystem === 'ios' || state.operatingSystem === 'android',
        isAppleDevice: (state) => state.operatingSystem === 'ios' || state.operatingSystem === 'mac',
        isAndroid: (state) => state.operatingSystem === 'android',
        browserName: (state) => {
            const ua = state.userAgent.toLowerCase();
            if (/firefox/.test(ua)) {
                return 'Firefox';
            } else if (/edg\//.test(ua)) {
                return 'Edge';
            } else if (/opr\//.test(ua) || /opera/.test(ua)) {
                return 'Opera';
            } else if (/chrome/.test(ua) && !/edg\//.test(ua)) {
                return 'Chrome';
            } else if (/safari/.test(ua) && !/chrome/.test(ua) && !/crios/.test(ua)) {
                return 'Safari';
            } else {
                return 'Unknown';
            }
        },
    },
    actions: {
        async fetchDeviceInfo() {
            const info = await Device.getInfo();

            this.device = info;
            this.operatingSystem = info.operatingSystem;
            this.platform = info.platform;
            if (typeof navigator !== 'undefined') {
                this.userAgent = navigator.userAgent;
            }
        },
    },
});
