import { reactive } from 'vue';

import type User from '@/models/types/user.model';
import type GeoPoint from '@/models/types/geo-point.model';
import type Popup from '@/models/types/popup.model';
import type Error from '@/models/types/error.model';
import type Success from '@/models/types/success.model';
import type DeliveryAddress from '@/models/types/delivery-address.model';
import type Location from '@/models/types/location.model';
import type Product from '@/models/types/product.model';
import type Cart from '@/models/types/cart.model';
import type Delivery from './models/types/delivery.model';

type TransitionName = 'slide-left' | 'slide-right' | 'fade-view';

const store = reactive({
    /**
     * Constants
     */
    constants: {
      SKAFFA_API_URL: import.meta.env.VITE_API_URL,

      // Endpoints
      CALLBACK_ENDPOINT: '/quickpay/callback',
      CLOSE_MODAL_CART_ENDPOINT: '/cart/close-payment-modal',
      CLOSE_MODAL_SETTINGS_ENDPOINT: '/settings/payment-methods/close-save-card-modal',
      CREATE_PAYMENT_ENDPOINT: '/quickpay/create-payment',
    },

    access_token: null as string | null,

    transitionName: 'slide-right' as TransitionName,
    user: null as User | null,
    geolocation: null as GeoPoint | null,
    popup: {} as Popup,
    error: {} as Error,
    success: {} as Success,
    deliveryAddress: null as DeliveryAddress | null,
    activeCategoryId: null as number | null,
    activeSplash: true,
    activeLoaderScreen: false,
    loaderScreenHasButton: true,
    termsModalOpen: false,

    /**
     * Checkout
     */
    cart: null as Cart | null,
    updateCart: false,
    orderId: '',
    availableDeliveryAreas: [] as Delivery[],
    activeOrderId: null as string | null,
    showPaymentModal: false,
    cancelPaymentPressed: false,

    /**
     * Payment Methods
     */
    showSaveCardModal: false,
    cancelSaveCard: false,
    allowApplePay: false,

    /**
     * Routing
     */
    goBack: false,
    checkRoute: true,
    settingsPathBeforeOpen: '',

    /**
     * Orderflow
     */
    location: null as Location | null,
    product: null as Product | null,
});

export default store;
