import store from '@/store';

import type Popup from '@/models/types/popup.model';

export function popup(options: Popup) {
    store.popup = {
        ...options,
        active: true,
    };
}
